<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card
          :loading="loading"
          :disabled="loading"
          loader-height="2"
          class=""
        >
          <v-card-text class="pb-0">
            <div class="d-flex align-center">
              <v-btn
                rounded
                color="primary"
                v-if="$nova.hasRight(right, 'add')"
                @click="addform()"
                elevation="0"
                small
                ><v-icon>mdi-plus</v-icon>Add</v-btn
              >
              <v-btn icon small class="ml-1" @click="getData()">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <lb-string
                class="ml-1"
                label="Search"
                outlined
                hidedetails
                v-model="search"
              ></lb-string>
              <v-spacer></v-spacer>
              <v-chip label small class="border-left-error" outlined
                >Disabled</v-chip
              >
            </div>
          </v-card-text>
          <v-spacer class="mt-3"></v-spacer>
          <v-dialog v-model="addAnalysisform" max-width="600" persistent>
            <v-card>
              <v-card-text
                class="primary white--text d-flex align-center py-2 px-4"
              >
                <span class="subtitle-1 white--text" v-if="!editId"
                  >Add New
                </span>
                <span class="subtitle-1 white--text" v-else>Update</span>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  @click="addAnalysisform = false"
                  class="ml-2 white--text"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-card-text>
              <v-card-text class="mt-4 pt-4">
                <v-row>
                  <v-col cols="12" class="my-0 py-1">
                    <lb-string
                      label="Name"
                      v-model="name"
                      :error="errors['name']"
                    />
                  </v-col>
                </v-row>
                <span class="subtitle-1 black--text"
                  ><b>Verifications :</b>
                </span>
                <v-btn
                  rounded
                  color="primary"
                  @click="areasDialog = true"
                  small
                  class="mr-2 ml-2 mb-1"
                  ><v-icon class="mr-1">mdi-plus</v-icon>Add</v-btn
                >
              </v-card-text>
              <v-card-text>
                <v-data-table
                  v-if="verificationTable.length > 0"
                  :headers="headingVerfiy"
                  :items="verificationTable"
                  :items-per-page="-1"
                  hide-default-footer
                  dense
                >
                  <template #[`item.index`]="{ index }">
                    <td>{{ index + 1 }}</td>
                  </template>

                  <template #[`item.code`]="{ item }">
                    <div class="font-weight-black">{{ item.code }}</div>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="text-center">
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            small
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            @click="remove(item)"
                          >
                            <v-icon>mdi-close-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>
                  </template>

                  <!-- <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click="remove(item)"
                      >mdi-close-circle</v-icon
                    >
                  </template> -->
                </v-data-table>
              </v-card-text>
              <v-card-text class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                  small
                  class="mb-2 ml-2"
                  color="primary"
                  v-if="editId"
                  @click="update()"
                  >Update</v-btn
                >
                <v-btn
                  small
                  class="mb-2 ml-2"
                  color="primary"
                  v-else
                  @click="saveVerifications()"
                  >Save</v-btn
                >
              </v-card-text>
            </v-card>
          </v-dialog>
          <div>
            <!-- <custom-dialog-box :visible="showDialog" heading="My Dialog" button-text="Save" @close="showDialog = false"> -->
            <!-- Content for the dialog box here -->
            <!-- </custom-dialog-box> -->
          </div>
          <DataTable
            :headers="headers"
            :items="formatedData"
            :enableslot="['action', 'code', 'verifications']"
            :loading="loading"
          >
            <template v-slot:code="{ item }">
              <div v-for="(verification, i) in item.code" :key="i">
                <v-chip label x-small>{{ verification.code }}</v-chip>
              </div>
            </template>
            <template v-slot:verifications="{ item }">
              <div v-for="(verification, i) in item.verifications" :key="i">
                {{ verification.name }}
              </div>
            </template>
            <template v-slot:action="{ item }">
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="$nova.hasRight(right, 'edit')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Bot</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="item.status && $nova.hasRight(right, 'enable/disable')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    @click="disableId(item._id)"
                  >
                    <v-icon>mdi-check-circle</v-icon>
                  </v-btn>
                </template>
                <span>Disable</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-else-if="$nova.hasRight(right, 'enable/disable')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="enableId(item._id)"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>Enable</span>
              </v-tooltip>
            </template>
          </DataTable>
          <!-- <v-data-table
            class="ml-3 mb-3"
            :headers="headers"
            :search="search"
            :items="dataTableOne"
            :items-per-page="-1"
            hide-default-footer
          >
            <template #[`item.name`]="{ item }">
              <td class="border-left-error" v-if="!item.status">
                {{ item.name }}
              </td>
              <td class="border-left-transparent" v-else>{{ item.name }}</td>
            </template>
            <template #[`item.code`]="{ item }">
              <div
                v-for="(verification, i) in item.verification_detail"
                :key="i"
              >
                <v-chip label x-small>{{ verification.code }}</v-chip>
              </div>
            </template>
            <template #[`item.verification_detail`]="{ value }">
              <div v-for="(verification, i) in value" :key="i">
                {{ verification.name }}
              </div>
            </template>
            <template v-slot:[`item._id`]="{ item }">
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="$nova.hasRight(right, 'edit')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Bot</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="item.status && $nova.hasRight(right, 'enable/disable')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    @click="disableId(item._id)"
                  >
                    <v-icon>mdi-check-circle</v-icon>
                  </v-btn>
                </template>
                <span>Disable</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-else-if="$nova.hasRight(right, 'enable/disable')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="enableId(item._id)"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>Enable</span>
              </v-tooltip> -->
          <!-- <v-menu offset-y transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="view-on-hover-item-d mx-0 px-0"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>mdi-menu</v-icon>
                  </v-btn>
                </template>
                <v-list dense class="pa-0 border-left-default">
                  <v-list-item @click="editItem(item)">
                    <v-list-item-title
                      ><v-icon left>mdi-pencil</v-icon>Edit</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item v-if="item.status" @click="disableId(item._id)">
                    <v-list-item-title
                      ><v-icon color="error" left>mdi-cancel</v-icon
                      >Disable</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item v-else @click="enableId(item._id)">
                    <v-list-item-title
                      ><v-icon left color="success">mdi-check-bold </v-icon
                      >Enable</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu> -->
          <!-- </template>
          </v-data-table> -->
        </v-card>
      </div>
    </div>
    <v-dialog v-model="areasDialog" max-width="600" persistent>
      <v-card>
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text">Add Areas</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="areasDialog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="pt-4 px-4">
          <div class="px-2 py-1 mt-1 mb-2 subtitle-2">
            Total Verification {{ this.selectedverification.length }} selected
          </div>
          <div v-for="(item, i) in areasList" :key="i" class="hoverable">
            <div
              class="px-2 py-1 d-flex align-start cursor-pointer"
              @click="expandCollapse(item._id)"
            >
              <v-checkbox
                @click.stop="
                  selectDeselectGroup(item._id);
                  calculateGroupSelection(item._id);
                  calculateGroupSelection(
                    (item.subarea || []).map((x) => x._id)
                  );
                "
                class="mt-0 pt-0"
                dense
                color="primary"
                hide-details
                :indeterminate="groupstage[item._id] === 1"
                :value="groupstage[item._id] === 2"
              ></v-checkbox>
              <span>{{ item.name }}</span>
              <v-spacer></v-spacer>
              <v-chip small label
                >{{ verificationCount[item._id] || 0 }} /
                {{ (verificationidlist[item._id] || []).length }}
                selected</v-chip
              >
              <v-icon
                class="mt-1 mx-2"
                v-if="expandedheads.indexOf(item._id) > -1"
                >mdi-chevron-down</v-icon
              >
              <v-icon class="mt-1 mx-2" v-else>mdi-chevron-right</v-icon>
            </div>
            <div
              :style="`${
                expandedheads.indexOf(item._id) === -1
                  ? 'height:0px;overflow:hidden'
                  : ''
              }`"
            >
              <div class="mx-5 border-left-grey-thin mb-2">
                <div
                  v-for="(subarea, si) in item.subarea"
                  :key="si"
                  class="hoverable"
                >
                  <div
                    class="px-2 py-1 d-flex align-start cursor-pointer"
                    @click="expandCollapse(subarea._id)"
                  >
                    <v-checkbox
                      @click.stop="
                        selectDeselectGroup(subarea._id);
                        calculateGroupSelection(subarea._id);
                        calculateGroupSelection(item._id);
                      "
                      class="mt-0 pt-0"
                      dense
                      color="primary"
                      hide-details
                      :indeterminate="groupstage[subarea._id] === 1"
                      :value="groupstage[subarea._id] === 2"
                    ></v-checkbox>
                    <span>{{ subarea.name }}</span>
                    <v-spacer></v-spacer>
                    <v-chip small label
                      >{{ verificationCount[subarea._id] || 0 }} /
                      {{ (verificationidlist[subarea._id] || []).length }}
                      selected</v-chip
                    >
                    <v-icon
                      class="mt-1 mx-2"
                      v-if="expandedheads.indexOf(subarea._id) > -1"
                      >mdi-chevron-down</v-icon
                    >
                    <v-icon class="mt-1 mx-2" v-else>mdi-chevron-right</v-icon>
                  </div>
                  <div
                    class="mx-5 border-left-grey-thin mb-2"
                    v-if="expandedheads.indexOf(subarea._id) > -1"
                  >
                    <div
                      v-for="(verify, svi) in subarea.verification"
                      :key="svi"
                      class="hoverable"
                    >
                      <div class="px-2 py-0 d-flex align-start">
                        <v-checkbox
                          class="mt-0 pt-0"
                          multiple
                          :value="verify._id"
                          v-model="selectedverification"
                          color="primary"
                          @change="
                            calculateGroupSelection(subarea._id);
                            calculateGroupSelection(item._id);
                          "
                          dense
                          hide-details
                        ></v-checkbox>
                        <span>{{ verify.name }}</span>
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>
                </div>
              </div>
            </div>
            <v-divider></v-divider>
          </div>

          <v-btn small class="mt-3" color="primary" @click="submitData()"
            >Submit</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DataTable from "../../../../views/common/DataTable.vue";
export default {
  data() {
    return {
      right: "conserve_bots",
      errors: {},
      areasList: [],
      expandedheads: [],
      search: "",
      loading: false,
      addAnalysisform: false,
      addVerifications: "",
      selectedverification: [],
      selectedarea: [],
      selectedsubarea: [],
      name: "",
      headingVerfiy: [
        { text: "#", value: "index" },
        { text: "Code", value: "code", sortable: false },
        { text: "Verifications", value: "name", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      // headers: [
      //   { text: "Name", value: "name" },
      //   { text: "Code", value: "code" },
      //   { text: "Verifications", value: "verification_detail" },
      //   { text: "Actions", value: "_id" },
      // ],
      headers: [
        {
          value: "indexno",
          text: "#",
          sortable: false,
          filterable: false,
          lock: true,
          visible: false,
        },
        {
          value: "name",
          text: "Name",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "code",
          text: "Code",
          sortable: true,
          lock: true,
          visible: false,
          datatype: "object",
        },
        {
          value: "verifications",
          text: "verifications",
          sortable: true,
          lock: true,
          visible: false,
          datatype: "object",
        },
        {
          value: "created_at",
          text: "Created At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "updated_at",
          text: "Updated At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "text-center",
        },
      ],
      areasForm: false,
      areasDialog: false,
      checkbox: [],
      dataTableOne: [],
      editId: null,
      verificationidlist: {},
      groupstage: {},
      verificationCount: {},
      verificationAll: [],
      verificationTable: [],
      formatedData: [],
    };
  },
  components: {
    DataTable,
  },
  created() {
    this.getFormData();
    this.getData();
  },
  activated() {
    this.getData();
  },
  methods: {
    formateData() {
      let ObjectTemp = {};
      let ArrayTemp = [];
      this.dataTableOne.forEach((item, index) => {
        ObjectTemp = {
          __key: index,
          __formatedkey: index,
          _id: item._id,
          indexno: index + 1,
          name: item.name,
          code: item.verification_detail,
          verifications: item.verification_detail,
          created_at: item.created_at,
          updated_at: item.updated_at,
          status: item.status,
        };
        ArrayTemp.push(ObjectTemp);
      });
      this.formatedData = ArrayTemp;
    },
    getFormData() {
      this.loading = true;
      this.axios
        .post("/v2/conserve/bot/getformdata")
        .then((ele) => {
          if (ele.data.status === "success") {
            this.areasList = ele.data.data;
            this.verificationidlist = {};
            this.verificationAll = [];

            for (const i of this.areasList) {
              for (const s of i.subarea) {
                for (const v of s.verification) {
                  this.verificationAll.push(v);
                  this.verificationidlist[i._id] =
                    this.verificationidlist[i._id] || [];
                  this.verificationidlist[s._id] =
                    this.verificationidlist[s._id] || [];
                  this.verificationidlist[i._id].push(v._id);
                  this.verificationidlist[s._id].push(v._id);
                }
              }
            }
          } else throw new Error(ele.data.message);
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    calculateGroupSelection(groupdata) {
      let gl = [];
      if (groupdata instanceof Array) gl = groupdata;
      else gl = [groupdata];
      for (const group of gl) {
        let element = this.verificationCount;
        let selectcount = 0;
        let ids = this.verificationidlist[group] || [];
        for (const i of ids) {
          if (this.selectedverification.indexOf(i) > -1) selectcount++;
        }
        element[group] = selectcount;
        this.verificationCount = JSON.parse(JSON.stringify(element));
        if (selectcount === 0) this.groupstage[group] = 0;
        else if (selectcount === ids.length) this.groupstage[group] = 2;
        else this.groupstage[group] = 1;
      }
    },

    submitData() {
      this.areasDialog = false;
      this.verificationTable = this.verificationAll.filter((el) => {
        return this.selectedverification.indexOf(el._id) !== -1;
      });
      return this.verificationTable;
    },
    saveVerifications() {
      this.loading = true;
      let data = { name: this.name, verification: this.selectedverification };
      this.axios
        .post("v2/conserve/bot/add/", { data })
        .then((ele) => {
          if (ele.data.status === "success") {
            this.$store.commit("sbSuccess", "added successfully");
            this.getData();
            this.addAnalysisform = true;
          } else {
            this.errors = {};
            this.addAnalysisform = false;
            if (ele.data.data.length > 0) {
              if (ele.data.data[0].index0)
                this.errors = ele.data.data[0].index0;
            } else throw new Error(ele.data.message || "Error adding client");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      this.axios
        .post("/v2/conserve/bot/get")
        .then((ele) => {
          if (ele.data.status === "success") {
            this.dataTableOne = ele.data.data;
            this.formateData();
          } else throw new Error(ele.data.message);
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove(item) {
      this.verificationTable.splice(this.verificationTable.indexOf(item), 1);
      this.selectedverification.splice(
        this.selectedverification.indexOf(item._id),
        1
      );
    },
    editItem(item) {
      let itemObject = JSON.parse(JSON.stringify(item));
      this.verificationTable = itemObject.verification_detail;
      this.selectedverification = itemObject.verification;
      this.name = itemObject.name;
      this.editId = itemObject._id;
      this.addAnalysisform = true;

      for (const i of this.areasList) {
        for (const s of i.subarea) {
          this.calculateGroupSelection(s._id);
        }
        this.calculateGroupSelection(i._id);
      }

      //this.calculateGroupSelection
    },
    addform() {
      this.addAnalysisform = true;
      this.name = "";
      this.verificationTable = [];
      this.selectedverification = [];
      this.editId = null;
      this.errors = {};
    },
    update() {
      this.addAnalysisform = false;
      let data = { name: this.name, verification: this.selectedverification };
      this.axios
        .post("/v2/conserve/bot/edit/" + this.editId, {
          data,
        })
        .then((ele) => {
          if (ele.data.message === "updated successfully") {
            this.$store.commit("sbSuccess", "updated successfully");
            this.getData();
            this.areasDialog = false;
          } else throw new Error(ele.data.message || "Error Update Bot");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enableId(id) {
      this.axios
        .post("/v2/conserve/bot/enable/" + id)
        .then((ele) => {
          if (ele.data.status === "success") {
            this.getData();
          } else throw new Error(ele.data.message || "Error enable Bot");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    disableId(id) {
      this.axios
        .post("/v2/conserve/bot/disable/" + id)
        .then((ele) => {
          if (ele.data.status === "success") {
            this.getData();
          } else throw new Error(ele.data.message || "Error disable Bot");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    expandCollapse(id) {
      let index = this.expandedheads.indexOf(id);
      if (index > -1) this.expandedheads.splice(index, 1);
      else this.expandedheads.push(id);
    },
    selectDeselectGroup(group) {
      let allselected = true;
      let selected = [];
      let unselected = [];
      for (const i of this.verificationidlist[group] || []) {
        if (this.selectedverification.indexOf(i) === -1) {
          allselected = false;
          unselected.push(i);
          // console.log(unselected,"unselected");
        } else selected.push(i);
      }
      if (allselected === true) {
        this.groupstage[group] = 0;
        for (const i of selected)
          this.selectedverification.splice(
            this.selectedverification.indexOf(i),
            1
          );
      } else {
        this.groupstage[group] = 2;
        for (const i of unselected) this.selectedverification.push(i);
      }
    },
  },
};
</script>